import website from '../assets/website.png'
import instagram from '../assets/instagram.png'
import appstore from '../assets/IMG_20201124_112759.png'
import youtube from '../assets/youtube.png'
import dribbble from '../assets/dribbble.png'
import github from '../assets/github.png'
import github2 from '../assets/github2.png'
import telegram from '../assets/IMG_20201124_104554.png'

const items = [{
    "title": "Website",
    "subtitle": "Latest thoughts",
    "image": website,
    "link": "https://thankyoudom.com"
},
{
    "title": "GitHub",
    "subtitle": "@... | 🏠 of my open-source projects",
    "image": github2,
    "link": "https://github.com/monsieurverde"
},
{
    "title": "Drizzy's Channel",
    "subtitle": "@dorizabelle | Lifestyle. Tech. Athletics",
    "image": youtube,
    "link": "https://youtube.com/c/Dorizabelle"
},]

export default items
