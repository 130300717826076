import React from 'react'

import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'

function Footer() {
    return (
        <div className = "Footer container">
            <p>Powered &nbsp;by <a href = 'https://belleit.net/'> &nbsp; Belle IT </a></p>
        </div>
    )
}

export default Footer
